<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer
                v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h3>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit"  }} Ways Of Learning</h3>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

            <div class="p-6">

                <!-- NAME -->
                <vs-input label="Name" v-model="name" class="w-full" name="item-name" v-validate="'required'"/>
                <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>

                <!-- Description -->
                <br/>
                <label for="description" class="vs-input--label">Description</label>
                <vs-textarea v-model="description"/>

                <!-- Ways of Learning Image -->
                <template v-if="dataImg">

                    <!-- Image Container -->
                    <div class="img-container w-64 mx-auto flex items-center justify-center">
                        <img :src="dataImg" alt="img" class="responsive">
                    </div>

                    <!-- Image upload Buttons -->
                    <div class="modify-img flex justify-between my-5">
                        <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
                        <vs-button class="mr-4" @click="$refs.updateImgInput.click()">Update Image</vs-button>
                        <vs-button class="vs-button-secondary" @click="dataImg = null">Remove Image</vs-button>
                    </div>
                </template>

                <div class="upload-img mt-5" v-if="!dataImg">
                    <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
                    <vs-button @click="$refs.uploadImgInput.click()">Upload Image</vs-button>
                </div>
                <vs-alert  v-if="customError != ''"
                           icon-pack="feather"
                           close-icon="icon-x"
                           color="danger"
                >{{customError}}
                </vs-alert>
            </div>
        </VuePerfectScrollbar>

        <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
            <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
        </div>
    </vs-sidebar>
</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import axios from "../../../../../../http/axios/index";
    //import moduleWaysOfLearning from "@/store/ways-of-learning/moduleWaysOfLearning.js"
    import {mapActions} from "vuex";


    export default {
        props: {
            isSidebarActive: {
                type: Boolean,
                required: true
            },
            data: {
                type: Object,
                default: () => {
                },
            }
        },
        watch: {
            isSidebarActive(val) {
                if (!val) return
                this.customError = "";

                if (Object.entries(this.data).length === 0) {
                    this.initValues()
                    this.$validator.reset()
                } else {
                    this.dataId = this.data._id
                    this.code = this.data.code
                    this.name = this.data.name
                    this.dataImg = this.data.icon
                    //this.orders = this.data.orders
                    this.description = this.data.description
                }
            }
        },
        data() {
            return {
                customError: "",

                dataImg: null,
                dataId: null,
                name: "",
                description: "",
                image: null,
                //orders:0,
                settings: { // perfectscrollbar settings
                    maxScrollbarLength: 60,
                    wheelSpeed: .60,
                },
            }
        },
        computed: {
            isSidebarActiveLocal: {
                get() {
                    return this.isSidebarActive
                },
                set(val) {
                    if (!val) {
                        this.$emit('closeSidebar')

                    }
                }
            },
            isFormValid() {
                return !this.errors.any() && this.name && this.description
            }
        },
        methods: {
            ...mapActions("waysOfLearning", [
                "updateWaysOfLearning",
                "addWaysOfLearning",
            ]),
            showMessage(title, message, color) {
                this.$vs.notify({
                    title: title,
                    text: message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: color
                })
            },
            success() {
                this.$vs.notify({
                    title: 'Success',
                    text: 'Data Saved',
                    color: 'success',
                    iconPack: 'feather',
                    position: 'top-right',
                    icon: 'icon-check-circle'
                })
            },
            error(err) {
                this.$vs.notify({
                    title: 'Error',
                    text: err.message,
                    color: 'error',
                    iconPack: 'feather',
                    position: 'top-right',
                    icon: 'icon-times'
                })
            },
            initValues() {
                if (this.data.id) return
                this.dataId = null
                this.name = ""
                this.description = null
                this.dataImg = null
                this.image = null

            },
            async submitData() {
                await this.$validator.validateAll().then(result => {
                    if (result) {
                        let data = new FormData();

                        data.append('name', this.name);
                        data.append('description', this.description);
                        //obj.append('orders', this.orders);
                        data.append('image', this.image);
                        data.append('waysOfLearningId', this.dataId);

                        if (this.dataId !== null) {

                            if(this.dataImg == null){
                                this.customError = "Image field is required.";
                                return ;
                            }

                            this.$vs.loading()
                            this.updateWaysOfLearning(data)
                                .then((res) => {
                                    this.$vs.loading.close()
                                    this.showMessage("Success", "Ways of learning updated successfully.", "success");
                                    this.initValues()
                                    this.$emit('closeSidebar')
                                })
                                .catch(err => {
                                    this.$vs.loading.close()
                                    console.error(err)
                                })

                            /*this.$store.dispatch("moduleWaysOfLearning/updateWaysOfLearning", data)
                                .then((res) => {
                                    this.showMessage("Success", "Ways of learning updated successfully.", "success");
                                })
                                .catch(err => {
                                    this.showMessage("Error", err.message, "warning");
                                })*/


                            /*axios.post('admin/update-ways-of-learning/' + this.dataId, data, config).then(c => {
                                this.success();
                                this.$parent.getData();
                            }).catch(err => {
                                this.error(err);
                                this.$parent.getData();
                            })*/

                        } else {

                            if(this.dataImg == null){
                                this.customError = "Image field is required.";
                                return ;
                            }

                            this.$vs.loading()
                            this.addWaysOfLearning(data)
                                .then((res) => {
                                    this.$vs.loading.close()
                                    this.showMessage("Success", "Ways of learning added successfully.", "success");
                                    this.initValues()
                                    this.$emit('closeSidebar')
                                })
                                .catch(err => {
                                    this.$vs.loading.close()
                                    this.customError = err.response.data.message;
                                })


                            /*this.$store.dispatch("moduleWaysOfLearning/addWaysOfLearning", data)
                                .then((res) => {
                                    this.showMessage("Success", "Ways of learning added successfully.", "success");
                                })
                                .catch(err => {
                                    this.showMessage("Error", err.message, "warning");
                                })*/

                        }

                        /*this.initValues()
                        this.$emit('closeSidebar')*/

                    }
                })
            },
            updateCurrImg(input) {
                if (input.target.files && input.target.files[0]) {
                    this.image = input.target.files[0];
                    var reader = new FileReader()
                    reader.onload = e => {
                        this.dataImg = e.target.result
                    }
                    reader.readAsDataURL(input.target.files[0])
                }
            }

        },
        components: {
            VuePerfectScrollbar,
        },
        /*created() {

            if (!moduleWaysOfLearning.isRegistered) {
                this.$store.registerModule('moduleWaysOfLearning', moduleWaysOfLearning)
                moduleWaysOfLearning.isRegistered = true
            }
        }*/
    }
</script>

<style lang="scss" scoped>
    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 400px;
            max-width: 90vw;

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
    }
</style>
