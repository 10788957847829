<template>
    <div>
        <ways-of-learning-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData"/>
        <vx-card>
            <div class="op-block mt-5 p-0 shadow-none">
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="3" vs-xs="6">
                        <h3 class="font-bold h4">Ways of Learning</h3>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-lg="9" vs-sm="7" vs-xs="6">
                        <h3 class="font-bold h4">Description</h3>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="2" vs-xs="12">
                        <vs-button class="mr-4 custom-vs-btn" @click="addNewData">Add</vs-button>
                    </vs-col>
                    <vs-divider/>
                </vs-row>

                <vs-row :key="index" v-for="(item,index) in waysOfLearning">
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="3" vs-xs="12" style="flex-wrap: wrap;">
                        <div class="large mb-1" style="width: 100px; height: 100px;">
                            <img v-if="item.icon != ''" :src="item.icon" 
                                 class="inline-block" style="height:75px; object-fit: contain;">
                            <img v-else src="../../../../../../assets/images/personhood/no-img.png" width="100%" height="100%"
                                 class="inline-block" style="object-fit: scale-down">
                        </div>
                        <h5 class="font-bold mb-2" style="display: flex; flex: 0 0 100%; justify-content: center;">{{item.name}}</h5>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="9" vs-sm="7" vs-xs="12">
                        <p class="h5 mb-2 text-justify">{{item.description}}</p>
                    </vs-col>

                    <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="2" vs-xs="12">
                        <a href="#" @click.stop="editData(item)">
                        <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                      />
                        </a>
                            <a href="#" @click.stop="deleteData(item._id)">
                        <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                                      />
                            </a>
                    </vs-col>
                    <vs-divider/>
                </vs-row>
            </div>
        </vx-card>

    </div>
</template>
<script>
    import WaysOfLearningSidebar from "./WaysOfLearningSidebar";
    import Swal from 'sweetalert2/dist/sweetalert2';
    import 'sweetalert2/src/sweetalert2.scss'
    //import moduleWaysOfLearning from "@/store/ways-of-learning/moduleWaysOfLearning.js"
    import {mapActions} from "vuex";


    export default {
        components: {WaysOfLearningSidebar},
        data() {
            return {
                path:'',
                selected: [],
                waysOfLearning: [],
                itemsPerPage: 4,
                isMounted: false,

                // Data Sidebar
                addNewDataSidebar: false,
                sidebarData: {},
            }
        },
        computed: {
            currentPage() {
                if (this.isMounted) {
                    return this.$refs.table.currentx
                }
                return 0
            },

            queriedItems() {
                return this.$refs.table ? this.$refs.table.queriedResults.length : this.waysOfLearning.length
            }
        },
        methods: {
            ...mapActions("waysOfLearning", [
                "fetchWaysOfLearning",
                "deleteWaysOfLearning",
            ]),
            showMessage(title, message, color) {
                this.$vs.notify({
                    title: title,
                    text: message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: color
                })
            },
            addNewData() {
                this.sidebarData = {}
                this.toggleDataSidebar(true)
            },
            deleteData(id) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.delete(id)
                    }
                })

            },
            async delete(id) {

                this.$vs.loading()
                this.deleteWaysOfLearning(id)
                    .then((res) => {
                        this.$vs.loading.close()
                        this.showMessage("Success", "Ways of learning deleted successfully.", "success");
                        this.waysOfLearning = this.$store.state.waysOfLearning.waysOfLearning;
                    })
                    .catch(err => {
                        this.$vs.loading.close()
                        console.error(err)
                    })

                /*this.$store.dispatch("moduleWaysOfLearning/deleteWaysOfLearning", id)
                    .then((res) => {
                        this.showMessage("Success", "Ways of learning deleted successfully.", "success");
                        this.waysOfLearning = this.$store.state.moduleWaysOfLearning.waysOfLearning;
                    })
                    .catch(err => {
                        this.showMessage("Error", err.message, "warning");
                        console.error(err)
                    })*/


                /*await  axios.get('admin/delete-ways-of-learning/' + id).then(c => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Deleted Successfully',
                        color: 'success',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-check-circle'
                    })
                    //this.getData();
                }).catch(err => {
                    this.$vs.notify({
                        title: 'Error',
                        text: err.message,
                        color: 'danger',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-times-circle'
                    })
                });*/
            },
            editData(data) {
                // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
                this.sidebarData = data
                this.toggleDataSidebar(true)
            },
            toggleDataSidebar(val = false) {
                this.addNewDataSidebar = val
            },
            /*getData: async function () {
                await axios.get('admin/get-ways-of-learning')
                    .then(waysOfLearning => {
                        let self = this;
                        self.waysOfLearning = waysOfLearning.data.data;
                    }).catch((err) => {
                        console.log(err.message);
                    })
            },*/

        },
        created() {

            this.$vs.loading()
            this.fetchWaysOfLearning()
                .then((res) => {
                    this.$vs.loading.close()
                    this.waysOfLearning = this.$store.state.waysOfLearning.waysOfLearning;
                })
                .catch(err => {
                    this.$vs.loading.close()
                    console.error(err)
                })

            /*if (!moduleWaysOfLearning.isRegistered) {
                this.$store.registerModule('moduleWaysOfLearning', moduleWaysOfLearning)
                moduleWaysOfLearning.isRegistered = true

                this.$store.dispatch("moduleWaysOfLearning/fetchWaysOfLearning")
                    .then((res) => {
                        this.waysOfLearning = this.$store.state.moduleWaysOfLearning.waysOfLearning;
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }else{
                this.waysOfLearning = this.$store.state.moduleWaysOfLearning.waysOfLearning;
            }*/
        }
    }
</script>
<style scoped lang="scss">
  .justify-text {
    @media (max-width: 600px){
      text-align: justify;
    }
  }
</style>
